import { CriteriaBase } from '@/shared/models';
import { Operation } from '../../../models';

/**
 * Modèle pour les critères de recherche des utilisateurs.
 * */
export class EchantillonCriteria extends CriteriaBase {

    /**
     * le nom du dépôt.
     */
    public libelle: string = '';

    // Date début EngagementTravaux.
    public dateDebutEngagementTravaux: string = '';

    // Date fin EngagementTravaux.
    public dateFinEngagementTravaux: string = '';

    // Date début FinalisationTravaux.
    public dateDebutAchevementTravaux: string = '';

    // Date fin FinalisationTravaux.
    public dateFinAchevementTravaux: string = '';

    public organismeControleId: number;

    public dateTransmissonOrganismeControle: string = '';

    public idTemplateOperation: number;

    public operationsSelected: Operation[] = [];

    public operationsIdSelected: number[];

    public statutOrganismeControleChoisi: number;

    public statutEchantillonChoisi: number;

    public echantillonsSelectedId: number = 0;

    public echantillonsSelectedId2: number = 0;

    public estCasPrecarite: boolean;

    public typeControleChoisi: number;
}