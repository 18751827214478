



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep, debounce } from 'lodash-es';

@Component({
    name: 'CeeAutocomplete',
    inheritAttrs: false,
    model: {
        event: 'input-cee-autocomplete'
    },
})
export default class CeeAutocomplete<T> extends Vue {
    // Définition de refs.
    public $refs!: Vue['$refs'] & {
        autocomplete: {
            setValue: ((value: any) => void),
            updateSelf: (() => void),

            itemText: string,
            itemValue: string,
        }
    };
    /**
     * Pour utiliser v-model au niveau du composant en définissant la propriété 'value' et mise à jour avec l'événement 'input'
     * Voir https://fr.vuejs.org/v2/guide/components.html#Personnalisation-de-composant-avec-v-model
     * */
    @Prop()
    public value: T;

    @Prop()
    public hint: string;

    // Nombre de caractères avant de lancer une recherche..
    @Prop({ default: 2 })
    public minimumCharBeforeSearch!: number;

    // Fonction permettant de récupérer les items.
    @Prop()
    public searchPromise: (searchInput: T | string | number) => Promise<any[]>;

    // Fonction permettant de récupérer les items pour les update.
    @Prop()
    public searchUpdatePromise: (searchInput: T | string | number) => Promise<any[]>;

    // Les items de la liste.
    public itemList: any[] = [];

    // Sauvegarde le texte sélectionné pour éviter une double recherche.
    public selectedItemText: string | null = null;

    // Sauvegarde le texte sélectionné pour éviter une double recherche.
    public selectedItemValue: string | null = null;

    // Indique un chargement en cours.
    public loading = false;

    // Input de recherche.
    public search: string = null;

    // Les attributs du composant.
    public get attrs(): Record<string, any> {
        const { minimumCharBeforeSearch , ...attrs } = this.$attrs;
        const finalAttrs = {
            ...{
                itemValue: "id",
                itemText: "libelle",
            } as Record<any, any>,
            ...attrs as unknown as  Record<any, any>,
            ...{
                autocomplete: "off",
                hideNoData: true,
                hideDetails: false,
                clearable: true,
                returnObject: true,
                hideSelected: false,
                cacheItems: false,
                solo: false,
            } as Record<any, any>,
        } as Record<any, any>;

        return finalAttrs;
    }

    public mounted() {
        this.recupererItems('');
    }

    // Les événements du composant.
    public get listeners(): Record<string, any> {
        const { input, ...listeners  } = this.$listeners;
        return listeners as unknown as Record<string, any>;
    }

    // événements Input du composant.
    public input(event: any): void {
        if (event) {
            const text = (event || {})[this.$refs.autocomplete.itemText] || null;
            const value = (event || {})[this.$refs.autocomplete.itemValue] || null;
            this.selectedItemText = text;
            this.selectedItemValue = value;
            this.$emit('input-cee-autocomplete', value);
            this.$emit('input', event);
        }
    }

    /**
     * Watcher afin de savoir quand chercher des items.
     * @param value Valeur de l'input de recherche.
     */
    @Watch('value', { immediate: true })
    public valueChanged(val: any) {
        const value = cloneDeep(val);
        
        if (!this.isEmptyOrWhiteSpaces(value)
            && (val !== this.selectedItemText && val !== this.selectedItemValue)) {
            this.loading = true;
            this.searchUpdatePromise(value).then((result: any) => {
                if (result) {
                    let data: any | null = null;
                    if (Array.isArray(result)) {

                        // Si c'est un tableau.
                        this.itemList = result as any[];
                        data = result.find(element => element[this.$refs.autocomplete.itemValue] === this.value) || {};

                    } else {
                        // Si c'est un seul élément.
                        this.itemList.push(result);
                        data = result;
                    }

                    this.selectedItemText = data[this.$refs.autocomplete.itemText] || null;
                    this.selectedItemValue = data[this.$refs.autocomplete.itemValue] || null;
                }
            }).finally(() => this.loading = false);
        }
    }
    /**
     * Watcher afin de savoir quand chercher des items.
     * @param value Valeur de l'input de recherche.
     */
    @Watch('search', { immediate: false })
    public searchChanged(input: T | string | number) {
        input
            && !this.isEmptyOrWhiteSpaces(input)
            && (input !== this.selectedItemText && input !== this.selectedItemValue)
            && this.debounceRecupererItems(input);
    }
    /**
     * Récupération des items.
     * @param input Input de recherche.
     */
    public debounceRecupererItems = debounce(this.recupererItems, 250, {});

    /**
     * Récupération des items.
     * @param input Input de recherche.
     */
     public recupererItems(input: T | string | number): void {
        if (input.toString().length >= this.minimumCharBeforeSearch) {
            this.loading = true;
            this.searchPromise(input)
                .then((result) => {
                    this.itemList = result;
                })
                .catch(() => this.itemList = [])
                .finally(() => this.loading = false);
        }
        else {
            this.itemList = [];
        }
    }

    /**
     * Test la nullité.
     * @param input
     */
    public isEmptyOrWhiteSpaces(input: T | string | number) {
        return input === null || typeof input === 'undefined' || !input || String(input).match(/^ *$/) !== null;
    }
}
